import { classNames } from '@/components/shared/classNames'
import styles from './Title.module.scss'

export type IDecorationType = 'blue-underline' | 'dark-blue-pill'

interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  spanColor?:
    | 'none'
    | 'blue-darkest'
    | 'blue-dark'
    | 'blue-medium'
    | 'blue'
    | 'blue-light'
    | 'gray-darkest'
    | 'gray-dark'
    | 'gray'
    | 'gray-medium'
    | 'gray-light'
    | 'gray-lightest'
    | 'purple-dark'
    | 'purple-medium'
    | 'purple'
    | 'magenta'
    | 'green'
    | 'green-dark'
    | 'white'
    | 'black'
    | 'error'
  className?: string
  children?: React.ReactNode
  decoration?: boolean
  decorationType?: 'blue-underline' | 'dark-blue-pill'
  center?: boolean
  right?: boolean
  dangerouslySetInnerHTML?: {
    __html: string
  }
  style?: React.CSSProperties
  wrapperClassName?: string
  classColor?: string
}

const TITLE_CLASSES: {
  'title-h1': string
  'title-h2': string
  'title-h3': string
  'title-h4': string
  'title-h5': string
  'title-h6': string
} = {
  'title-h1': 'title-h1',
  'title-h2': 'title-h2',
  'title-h3': 'title-h3',
  'title-h4': 'title-h4',
  'title-h5': 'title-h5',
  'title-h6': 'title-h6'
}

const DECORATION_CLASSES: {
  'blue-underline': string
  'center-blue-underline': string
  'dark-blue-pill': string
  'center-dark-blue-pill': string
} = {
  'blue-underline': 'decoration-blue-underline',
  'center-blue-underline': 'decoration-center-blue-underline',
  'dark-blue-pill': 'decoration-dark-blue-pill',
  'center-dark-blue-pill': 'decoration-center-dark-blue-pill'
}

const getDecorationClass = (decoration: boolean, decorationType: IDecorationType, center: boolean) => {
  if (!decoration) return ''
  if (center) return DECORATION_CLASSES[`center-${decorationType}`]
  return DECORATION_CLASSES[decorationType]
}

const Title = ({
  tag = 'h1',
  decoration = false,
  className,
  spanColor = 'none',
  children = null,
  center = false,
  decorationType = 'blue-underline',
  right,
  dangerouslySetInnerHTML,
  style,
  wrapperClassName,
  classColor = 'text-blue-darkest'
}: Props) => {
  const Tag = tag
  const centerClass = center ? 'text-center' : ''
  const rightClass = right ? 'text-right' : ''
  const decorationClass = getDecorationClass(decoration, decorationType, center)

  return (
    <div className={classNames('flex flex-col', wrapperClassName)}>
      <Tag
        className={classNames(
          'font-semibold',
          TITLE_CLASSES[`title-${tag}`],
          styles[`childspan-${spanColor}`],
          centerClass,
          rightClass,
          classColor,
          className
        )}
        style={style}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: dangerouslySetInnerHTML.__html } : undefined}
      >
        {dangerouslySetInnerHTML ? null : children}
      </Tag>
      {decoration && <span className={decorationClass}></span>}
    </div>
  )
}

export default Title
